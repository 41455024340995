import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {

  return (
    <nav className="bg-blue-400 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/course" className="text-white text-2xl font-semibold">
          Iyab Administration
        </Link>
        <ul className="flex space-x-4">
          <li>
            <Link to="/course" className="text-white hover:text-blue-300">
              Courses
            </Link>
          </li>
          <li>
            <Link to="/module" className="text-white hover:text-blue-300">
              Modules
            </Link>
          </li>

          <li>
            <Link to="/studnet" className="text-white hover:text-blue-300">
              Students
            </Link>
          </li>
          <li>
            <Link to="/logout" className="text-white hover:text-blue-300">
            Logout
            </Link>
          </li>
       

        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
