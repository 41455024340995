import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { WithContext as ReactTags } from "react-tag-input";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import api from "./api";
import { toast } from 'react-toastify';
const AddCourseForm = ({
  isOpen,
  onRequestClose,
  onAddCourse,
  onUpdateCourse,
  newCourse: initialNewCourse,
  isEditMode,
}) => {
  const navigate = useNavigate();

  const [tags, setTags] = useState([]);
  const [domenTags, setDomenTags] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedCourseType, setSelectedCourseTypeOption] = useState("");

  const [newCourse, setNewCourse] = useState({
    CourseName: "",
    Description: "",
    CourseKeywords: [],
    DomenKeywords: [],
    ...initialNewCourse,
  });
  
  const CourseType = [
    { value: 'AI', label: 'Artificial Intelligence' },
    { value: 'DS', label: 'Data Science' },
   
    // Add more options as needed
  ];

  useEffect(() => {
    setNewCourse({
      CourseName: "",
      Description: "",
      CourseKeywords: [],
      DomenKeywords: [],
      ...initialNewCourse,
    });
    setTags(initialNewCourse?.CourseKeywords || []);
    setDomenTags(initialNewCourse?.DomenKeywords || []);
 
    if (initialNewCourse && initialNewCourse.CourseType) {
      console.log("initialNewCourse",initialNewCourse);
      const typeSelected= CourseType.find(a=>a.value===initialNewCourse.CourseType);

      setSelectedCourseTypeOption(typeSelected);
      //console.log("selectedCourseType",selectedCourseType);
      
    } else {
      setSelectedCourseTypeOption("");
    }
  }, [initialNewCourse]);

  const handleDelete = (i, type) => {
    if (type === "CourseKeywords") {
      const newTags = tags.slice(0);
      newTags.splice(i, 1);
      setTags(newTags);
    } else if (type === "DomenKeywords") {
      const newDomenTags = domenTags.slice(0);
      newDomenTags.splice(i, 1);
      setDomenTags(newDomenTags);
    }
  };

  const handleAddition = (tag, type) => {
    if (type === "CourseKeywords") {
      const newTags = [...tags, tag];
      setTags(newTags);
      handleClearErrors("CourseKeywords");
    } else if (type === "DomenKeywords") {
      const newDomenTags = [...domenTags, tag];
      setDomenTags(newDomenTags);
      handleClearErrors("DomenKeywords");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({ ...newCourse, [name]: value });

    // Clear the error when the user starts typing again
    handleClearErrors(name);
  };

  const handleClearErrors = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const tagClassNames = {
    tag:
      "inline-block bg-blue-500 text-white px-3 py-1 rounded-full mr-2 mb-2 pr-1",
    remove: "bg-red-500 text-white px-2 rounded-full ml-2 cursor-pointer ",
    tagInputField:
      "mt-3 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500",
  };

  const handleAction = isEditMode ? onUpdateCourse : onAddCourse;
  
  const isFieldEmpty = (value, fieldName) => {
    if (!value || !value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName} is required`,
      }));
      return true;
    } else {
      // Clear the error when the field is not empty
      handleClearErrors(fieldName);
      return false;
    }
  };


  const handleAddCourse = async () => {
    // Validate CourseName
    if (isFieldEmpty(newCourse.CourseName, 'CourseName')) {
      return;
    }

    // Validate Description
    if (isFieldEmpty(newCourse.Description, 'Description')) {
      return;
    }

    // Validate CourseKeywords
    if (tags.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        CourseKeywords: "At least one course keyword is required",
      }));
      return;
    } else {
      // Clear the error when CourseKeywords is not empty
      handleClearErrors("CourseKeywords");
    }

    // Validate DomenKeywords
    if (domenTags.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        DomenKeywords: "At least one domen keyword is required",
      }));
      return;
    } else {
      // Clear the error when DomenKeywords is not empty
      handleClearErrors("DomenKeywords");
    }

    // If all validations pass, proceed to handleAction
    const username = localStorage.getItem('username');
    const newCourseData = {
      CourseName: newCourse.CourseName.trim(),
      Description: newCourse.Description.trim(),
      CourseType:selectedCourseType.value,
      CourseKeywords: tags,
      DomenKeywords: domenTags,
      Username: username,
    };
   console.log("newCourse",newCourse);
    

    try {
   
      if(!isEditMode){
        
        await api.post("/Course/addCourse/",newCourseData);

        onAddCourse();
        setNewCourse({ CourseName: "", Description: "", CourseKeywords: [] });
        onRequestClose();
        toast.success('Course added successfully', {
          autoClose: 2000,
          hideProgressBar: false,
        });
      }else{
         
        
        await api.put("/Course/"+ newCourse._id,newCourseData);
        onAddCourse();
        setNewCourse({ CourseName: "", Description: "", CourseKeywords: [] });
        onRequestClose();
        toast.success('Course updated successfully', {
          autoClose: 2000,
          hideProgressBar: false,
        });
      }
  
    } catch (error) {
      toast.error('An error occurred, please try again', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      console.log("Error sending data to Nest API:", error);
        if(error?.response?.data?.statusCode === 401){
        navigate('/');
      }

    }

    handleAction(newCourseData);

    setNewCourse({
      CourseName: "",
      Description: "",
      CourseType:"",
      CourseKeywords: [],
      DomenKeywords: [],

    });
    setTags([]);
    setSelectedCourseTypeOption("");

    setDomenTags([]);
    onRequestClose();
  };

  const handleSelectCourseTypeChange = (selected) => {
    console.log("handleSelectCourseTypeChange",selected);
    setSelectedCourseTypeOption(selected); 
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 'auto', // Set the width of the dropdown
      margin: '10px 0', // Adjust margin as needed
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e2e8f0', // Change background color of selected items
    })
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Course Modal"
      ariaHideApp={false}
      className="overflow-y-auto"
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          width: "600px",
          height: "auto",
          maxHeight: "80vh",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          position: "relative",
        },
      }}
    >
      <div className="bg-white p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">
            {isEditMode ? "Edit Course" : "Add Course"}
          </h2>
          <button
            className="text-red-500 hover:text-red-700"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Course Name
          </label>
          <input
            type="text"
            name="CourseName"
            value={newCourse.CourseName}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          />

          {errors.CourseName && (
            <p className="text-red-500 text-sm">{errors.CourseName}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Course Description
          </label>
          <textarea
            placeholder="Description"
            type="text"
            name="Description"
            value={newCourse.Description}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
          />
          {errors.Description && (
            <p className="text-red-500 text-sm">{errors.Description}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="multiSelect">
            Select course type
          </label>
          <Select
            
            options={CourseType}
            value={selectedCourseType}  
            onChange={handleSelectCourseTypeChange}
            styles={customStyles}
            className="select"
            placeholder="Select option..."
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="tags"
          >
            course keywords:
          </label>
          <ReactTags
            className="mt-1 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            tags={tags}
            name="CourseKeywords"
            value={newCourse.CourseKeywords}
            handleDelete={(i) => handleDelete(i, "CourseKeywords")}
            handleAddition={(tag) => handleAddition(tag, "CourseKeywords")}
            placeholder="Add tags..."
            classNames={tagClassNames}
          />
          {errors.CourseKeywords && (
            <p className="text-red-500 text-sm">{errors.CourseKeywords}</p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="domenTags"
          >
            Domain keywords:
          </label>
          <ReactTags
            className="mt-1 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            tags={domenTags}
            name="DomenKeywords"
            value={newCourse.DomenKeywords}
            handleDelete={(i) => handleDelete(i, "DomenKeywords")}
            handleAddition={(tag) => handleAddition(tag, "DomenKeywords")}
            placeholder="Add domen tags..."
            classNames={tagClassNames}
          />
          {errors.DomenKeywords && (
            <p className="text-red-500 text-sm">{errors.DomenKeywords}</p>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleAddCourse}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
          >
            {isEditMode ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCourseForm;
