import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faTrash,
  faEdit,
  faCopy,
  faExplosion,
} from "@fortawesome/free-solid-svg-icons";
import AddModuleForm from "./AddModuleForm";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import api from "./api";
import { toast } from "react-toastify";
import Select from "react-select";

const ModuleTable = () => {
  const navigate = useNavigate();
  const [editedModule, setEditedModule] = useState(null);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [modules, setModules] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [isEditMode, setIsEditMode] = useState(false);
  const [coursesLookups, setCLookups] = useState([]);
  const [selectedCoursesOption, setSelectedCoursesOption] = useState("");
  const [selectedUserOption, setSelectedUserOption] = useState("");

  const [editedValues, setEditedValues] = useState({
    ModuleName: "",
    ModuleDescription: "",
    LearningObjectives: "",
    RelatedCourses: "",
    PrerequisitesModules: "",
    ModuleKeywords: [],
  });
  const [newRecordAdded, setNewRecordAdded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          let response;
          if (selectedCoursesOption && selectedUserOption) {
            // Send both CourseId and UserId parameters
            //  response = await api.get(`/modules/getModules?courseId=${selectedCoursesOption.value}&user=${selectedUserOption.value}`);
            //const decodedEmail = decodeURIComponent(selectedUserOption.value);

            response = await api.get("/Module/getModules", {
              params: {
                courseId: selectedCoursesOption.value,
                user: selectedUserOption.value,
              },
            });
          } else if (selectedCoursesOption) {
            // Send only CourseId parameter
            response = await api.get("/Module/getModules", {
              params: {
                courseId: selectedCoursesOption.value,
              },
            });
          } else if (selectedUserOption) {
            console.log("selectedUserOption", selectedUserOption);
            // Send only UserId parameter

            response = await api.get("/Module/getModules", {
              params: {
                user: selectedUserOption.value,
              },
            });
          } else {
            // No parameters, fetch all modules
            response = await api.get("/Module/getModules");
          }
          setModules(response.data);

          const lookupResponse = await api.get(
            "/Module/getModuleCoursesLookups"
          );

          console.log("lookupResponse", lookupResponse.data);
          const coursesOptiens = lookupResponse.data.Courses?.map((option) => ({
            value: option._id,
            label: option.name,
          }));
          console.log("coursesOptiens", coursesOptiens);
          //    const modulesOptiens = lookupResponse.data.Modules?.map(option => ({ value: option._id, label: option.name }));
          setCLookups(coursesOptiens);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.statusCode === 401) {
          navigate("/");
        }
      }
    };

    fetchData();
  }, [
    newRecordAdded,
    setModules,
    navigate,
    selectedCoursesOption,
    selectedUserOption,
  ]);

  const handleEditModeChange = (value) => {
    console.log("value", value);
    setIsEditMode(value);
  };
  const handleCopyModule = async (id) => {
    console.log("index", id);
 
    const editedModule = modules.find(a=>a._id == id);
    
    const username = localStorage.getItem("username");
    try {
      await api.post(`/Module/copyModule/${editedModule._id}/${username}`);
      setNewRecordAdded(true);
      toast.success("Copy Module successfully", {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (error) {
      console.log("Error sending data to Nest API:", error);
      toast.error("An error occurred, please try again", {
        autoClose: 2000,
        hideProgressBar: false,
      });
      if (error?.response?.data?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handlePreviewModule = async (id) => {
  
    const url = "/viewModule/" +id;
    window.open(url, "_blank");
  };

  const handleAddEditModule = (moduleData, isEditMode) => {
    if (isEditMode) {
      setModules((prevModules) =>
        prevModules.map((m) =>
          m?._id === editedModule?._id ? { ...m, ...moduleData } : m
        )
      );
    } else {
      setModules((prevModules) => [...prevModules, moduleData]);
      setNewRecordAdded(true);
    }

    setIsAddEditModalOpen(false);
    setEditedModule(null);
  };

  const handleEditClick = (module) => {
    setEditedModule(module);
    setIsAddEditModalOpen(true);
  };

  const handleSaveModule = (index) => {
    const updatedModules = [...modules];
    updatedModules[index] = {
      ModuleName: editedValues.ModuleName,
      ModuleDescription: editedValues.ModuleDescription,
      LearningObjectives: editedValues.LearningObjectives.split(",").map(
        (obj) => obj.trim()
      ),
      RelatedCourses: editedValues.RelatedCourses.split(",").map((obj) =>
        obj.trim()
      ),
      PrerequisitesModules: editedValues.PrerequisitesModules.split(",").map(
        (obj) => obj.trim()
      ),
      ModuleKeywords: editedValues.ModuleKeywords.map((obj) => obj.text)
        .split(",")
        .map((obj) => obj.trim()),
    };
    setModules(updatedModules);
  };

  const handleDeleteModule = (index) => {
    const updatedModules = modules.filter((_, i) => i !== index);
    setModules(updatedModules);
  };

  const filteredModules = modules.filter(
    (module) =>
      module?.ModuleName &&
      module?.ModuleName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredModules.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedModules = filteredModules.slice(startIndex, endIndex);
  const handleSearchByCourse = (selected) => {
    console.log(selected);

    setSelectedCoursesOption(selected);
  };
  const handleSearchByUser = (selected) => {
    console.log(selected);

    setSelectedUserOption(selected);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "auto", // Set the width of the dropdown
      margin: "10px 0", // Adjust margin as needed
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#e2e8f0", // Change background color of selected items
    }),
  };
  const handleCancelSearch = () => {
    setSelectedCoursesOption("");
    setSelectedUserOption("");
  };

  const UsersOptions = [
    { value: "Hamed Abdelhaq", label: "Hamed Abdelhaq" },
    { value: "Osama Daher", label: "Osama Daher" },
    { value: "Mohammad Jubran", label: "Mohammad Jubran" },
    { value: "George Yerousis", label: "George Yerousis" },

    // Add more options as needed
  ];
  return (
    <>
      <div className="flex flex-col mt-48 ml-6 mr-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="flex justify-between mb-6">
              <h2 className="text-2xl font-semibold">Modules</h2>
              <button
                className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsAddEditModalOpen(true)}
              >
                Add Module
              </button>
            </div>

            <div className="mb-4 relative">
              <input
                type="text"
                placeholder="Search Module"
                className="pl-10 pr-4 py-2 border rounded w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-2 top-2 text-gray-400 ml-2 mt-1"
              />
            </div>

            <div className="mb-6 grid grid-cols-4 gap-4">
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-1"
                  htmlFor="multiSelect"
                >
                  Search by course:
                </label>

                <Select
                  options={coursesLookups}
                  value={selectedCoursesOption}
                  onChange={handleSearchByCourse}
                  styles={customStyles}
                  className="select"
                  placeholder="Search by course"
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-1"
                  htmlFor="multiSelect"
                >
                  Search by User:
                </label>

                <Select
                  options={UsersOptions}
                  value={selectedUserOption}
                  onChange={handleSearchByUser}
                  styles={customStyles}
                  className="select"
                  placeholder="Search by course"
                />
              </div>
              <div>
                <button
                  onClick={handleCancelSearch}
                  className="mt-1 px-16 py-4 bg-gray-500 text-white rounded hover-bg-blue-700 ml-10 "
                >
                  Clear search
                </button>
              </div>
              <br/>
            </div>

            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {" "}
                      #{" "}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {" "}
                      Module Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Module Description
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Module level
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Learning Objectives
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {" "}
                      Created By
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Add Activites
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedModules.map((module, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                    >
                      <td className="px-4 py-2 text-left">{index + 1}</td>
                      <td className="px-4 py-2 text-left">
                        {module.ModuleName}
                      </td>

                      <td className="px-4 py-2 text-left">
                        {module.ModuleDescription.substring(0, 200) + "..."}
                      </td>
                      <td className="px-4 py-2 text-left">{module.Level}</td>
                      <td className="px-4 py-2 text-left">
                        {module.LearningObjectives.join(", ")}
                      </td>

                      <td className="px-4 py-2 text-left">
                        {module.CreatedBy}
                      </td>

                      <td className="px-4 py-2 text-left">
                        <button className="text-blue-500 hover-text-indigo-900 ml-2">
                          <Link
                            to={`/moduleActivity/${module._id}/${module.ModuleName}`}
                          >
                            <FontAwesomeIcon icon={faPlus} className="mr-1" />
                            Add Activities
                          </Link>
                        </button>
                      </td>
                      <td className="px-4 py-2 text-left">
                        {
                          <>
                            <button
                              onClick={() => handleEditClick(module)}
                              className="text-blue-500 hover-text-indigo-900 pr-4"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            {/*<button
                              onClick={() => handleDeleteModule(index)}
                              className="text-blue-500 hover-text-indigo-900  pr-4"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>*/}
                            <button
                              onClick={() => handleCopyModule(module._id)}
                              className="text-blue-500 hover-text-indigo-900   pr-4"
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                title="Copy Module!"
                              />
                            </button>

                            <button
                              onClick={() => handlePreviewModule(module._id)}
                              className="text-blue-500 hover-text-indigo-900"
                            >
                              <FontAwesomeIcon
                                icon={faExplosion}
                                title="Preview Module!"
                              />
                            </button>
                          </>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center mt-4">
              <nav className="block">
                <ul className="flex pl-0 list-none rounded-lg">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li key={index}>
                      <button
                        className={`${
                          index + 1 === currentPage
                            ? "bg-blue-500 text-white"
                            : "bg-white text-blue-500"
                        } hover-bg-blue-300 px-4 py-2 border border-blue-500 hover-border-blue-300`}
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {isAddEditModalOpen && (
        <AddModuleForm
          isOpen={isAddEditModalOpen}
          onRequestClose={() => setIsAddEditModalOpen(false)}
          onAddModule={(moduleData) => {
            handleAddEditModule(moduleData, false);
            handleEditModeChange(true); // Update isEditMode to true after adding record
          }}
          onUpdateModule={(moduleData) => {
            handleAddEditModule(moduleData, true);
            handleEditModeChange(true); // Update isEditMode to true after updating record
          }}
          newModule={editedModule || {}}
          isEditMode={!!editedModule}
        />
      )}
    </>
  );
};

export default ModuleTable;
