import React from "react";
import Modal from "react-modal";

const DeleteCourseModal = ({
  isOpen,
  onRequestClose,
  course,
  setCourses,
  deleteId,
  setDeleteId,
}) => {
  const handleConfirmDelete = () => {
    if (deleteId !== null) {
      const updatedModules = course.filter((_, i) => i !== deleteId);
      setCourses(updatedModules);
      setDeleteId(null);
    }
    onRequestClose();
  };

  const handleCancelDelete = () => {
    setDeleteId(null);
    onRequestClose();
  };
  console.log(deleteId);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Delete Confirmation Modal"
        ariaHideApp={false}
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "450px",
            height: "200px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            position: "relative",
          },
        }}
      >
        <h2 className="text-xl font-semibold mb-4 mt-2">
          Are you sure you want to delete this item?
        </h2>
        <div className="flex justify-end mt-16">
          <button
            onClick={handleConfirmDelete}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Confirm
          </button>
          <button
            onClick={handleCancelDelete}
            className="text-indigo-500 hover:text-indigo-900"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteCourseModal;
