import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import Select from "react-select";
import { WithContext as ReactTags } from "react-tag-input";
import { useNavigate } from 'react-router-dom';
import api from "./api";
import { toast } from 'react-toastify';
const AddModuleForm = ({
  isOpen,
  onRequestClose,
  onAddModule,
  onUpdateModule,
  newModule: initialNewModule,
  isEditMode
}) => {
  const navigate = useNavigate();
  const [newLearningObjective, setNewLearningObjective] = useState("");
  const [learningObjectives, setLearningObjectives] = useState([]);
  const [coursesLookups, setCLookups] = useState([]);
  const [modulesLookups, setMLookups] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedCoursesOptions, setSelectedCoursesOptions] = useState([]);
  const [selectedLevel, setSelectedLevelOption] = useState("");
  const [selectedTechnology, setSelectedTechnologyOption] = useState("");
  const [selectedModulesOptions, setSelectedModulesOptions] = useState([]);
  const [errors, setErrors] = useState([])
  const [newModule, setNewModule] = useState({
    ModuleName: "",
    ModuleDescription: "",
    LearningObjectives: [],
    Level: "",
    RelatedCourses: [],
    PrerequisitesModules: [],
    ModuleKeywords: [],
    ...initialNewModule,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lookupResponse =  await api.get("/Module/getModuleCoursesLookups");

        console.log("lookupResponse", lookupResponse.data);
        const coursesOptiens = lookupResponse.data.Courses?.map(option => ({ value: option._id, label: option.name }));
        const modulesOptiens = lookupResponse.data.Modules?.map(option => ({ value: option._id, label: option.name }));

        setCLookups(coursesOptiens);
        setMLookups(modulesOptiens);
      } catch (error) {
        toast.error('An error occurred, please try again', {
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.log(error);
      }
    };

    fetchData();
    console.log("initialNewModule",initialNewModule);
    setLearningObjectives(initialNewModule?.LearningObjectives || []);
    const levelSelected= levelsOptions.find(a=>a.value===initialNewModule.Level);
    console.log("levelSelected",levelSelected);
    setSelectedLevelOption(levelSelected?levelSelected:""); 
    const technologySelected= technologyOptions.find(a=>a.value===initialNewModule.Technology);
    setSelectedTechnologyOption(technologySelected?technologySelected:"" ); 
    setSelectedModulesOptions(initialNewModule?.PrerequisitesModules);
    setSelectedCoursesOptions(initialNewModule?.RelatedCourses);
    setTags(initialNewModule?.ModuleKeywords || []);

  }, [initialNewModule]);

  const validateForm = () => {
    const errors = {};

    if (!newModule.ModuleName.trim()) {
      errors.ModuleName = "Module Name is required";
    }
    if (!newModule.ShortName.trim()) {
      errors.ShortName = "Short Name is required";
    }
    if (!newModule.ModuleDescription.trim()) {
      errors.ModuleDescription = "Module Description is required";
    }
    if (!selectedLevel) {
      errors.Level = "Select a level for this module"
    }
    if (!selectedTechnology) {
      errors.Technology = "Select a technology for this module"
    }

    if (learningObjectives.length === 0) {
      errors.LearningObjectives = "At least one Learning Objective is required";
    }
    if (selectedCoursesOptions.length ===0) {
      errors.RelatedCourses = "Select a releted courses for this module";
    }

 

    if (tags.length === 0) {
      errors.ModuleKeywords = "At least one Module Keyword is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };


  const handleDelete = (i) => {
    const newTags = tags.slice(0);
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    setTags(newTags);
  };

  const handleSelectCoursesChange = (selected) => {
    setSelectedCoursesOptions(selected);
  };

  const handleSelectModulesChange = (selected) => {
    setSelectedModulesOptions(selected);
  };
  const levelsOptions = [
    { value: 'level1', label: 'Level1' },
    { value: 'level2', label: 'Level2' },
    { value: 'level3', label: 'Level3' },
   
    // Add more options as needed
  ];

  const technologyOptions = [
    { value: 'Excel', label: 'Excel' },
    { value: 'KNIME', label: 'KNIME' },
    { value: 'Python', label: 'Python' },
   
    // Add more options as needed
  ];
  const handleSelectTechnologyChange = (selected) => {
    console.log(selected);
    setSelectedTechnologyOption(selected); 
  };
  const handleSelectLevelChange = (selected) => {
    console.log(selected);
    setSelectedLevelOption(selected); 
  };

  const tagClassNames = {
    tag: 'inline-block bg-blue-500 text-white px-3 py-1 rounded-full mr-2 mb-2 pr-1',
    remove: 'bg-red-500 text-white px-2 rounded-full ml-2 cursor-pointer ',
    tagInputField: 'mt-3 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500',

  };
  const handleAddLearningObjective = () => {
    if (!newLearningObjective.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        LearningObjectives: "Learning Objective cannot be empty",
      }));
      return;
    }

    setLearningObjectives([...learningObjectives, newLearningObjective]);
    setNewModule({
      ...newModule,
      LearningObjectives: [
        ...newModule.LearningObjectives,
        newLearningObjective,
      ],
    });
    setNewLearningObjective("");
    handleClearErrors("LearningObjectives");
  };

  const handleRemoveLearningObjective = (index) => {
    const updatedObjectives = [...learningObjectives];
    updatedObjectives.splice(index, 1);
    setLearningObjectives(updatedObjectives);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewModule({ ...newModule, [name]: value });

  // Clear the error when the user starts typing again
    handleClearErrors(name);
  };

  const handleAction = isEditMode ? onUpdateModule : onAddModule;


  const handleClearErrors = (fieldName) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };


  const handleAddModule = async () => {
 console.log("isEditMode",isEditMode);

    console.log(errors);
    if (validateForm()) {
      let newModuleData = {}; 
      const username = localStorage.getItem('username');
      newModuleData = {
        ModuleName: newModule.ModuleName?.trim(),
        ShortName:newModule.ShortName?.trim(),
        ModuleDescription: newModule.ModuleDescription?.trim(),
        LearningObjectives: learningObjectives,
        RelatedCourses: selectedCoursesOptions,
        Level: selectedLevel.value,
        Technology:selectedTechnology.value,
        PrerequisitesModules: selectedModulesOptions,
        ModuleKeywords: tags,
        Username:username
      };
      try {
        console.log("newModuleData",newModuleData);
        console.log("newModule._id",newModule._id);
        if(!isEditMode){
        await api.post("/Module/addModule/",newModuleData);
        toast.success('Module added successfully', {
          autoClose: 2000,
          hideProgressBar: false,
        });
        }else{
          await api.put("/Module/"+ newModule._id,newModuleData);
          toast.success('Module updated successfully', {
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
        onAddModule();
        handleAction(newModuleData);


        setNewModule({
          ModuleName: "",
          ShortName:"",
          ModuleDescription: "",
          LearningObjectives: [],
          Level: "",
          Technology:"",
          RelatedCourses: [],
          PrerequisitesModules: [],
          ModuleKeywords: [],
        });

        setLearningObjectives([]);
        setTags([]);
        setSelectedCoursesOptions([]);
        setSelectedLevelOption("");
        setSelectedTechnologyOption("");
        setSelectedModulesOptions([]);
        onRequestClose();
       
      } catch (error) {
        toast.error('An error occurred, please try again', {
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.log("Error sending data to Nest API:", error);
          if(error?.response?.data?.statusCode === 401){
          navigate('/');
        }
      }

    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 'auto', // Set the width of the dropdown
      margin: '10px 0', // Adjust margin as needed
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e2e8f0', // Change background color of selected items
    })
  };



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Module Modal"
      ariaHideApp={false}
      className="overflow-y-auto"
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          width: "700px",
          height: "auto",
          maxHeight: "80vh",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          backgroundColor: "white",
          position: "relative",
        },
      }}
    >
      <div className="bg-white p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">

            {isEditMode ? "Edit Module" : "Add Module"}
          </h2>
          <button
            className="text-red-500 hover:text-red-700"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Module Name
          </label>
          <input
            type="text"
            name="ModuleName"
            value={newModule.ModuleName}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          />
          {errors.ModuleName && (
            <p className="text-red-500 text-sm">{errors.ModuleName}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Short Name
          </label>
          <input
            type="text"
            name="ShortName"
            value={newModule.ShortName}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
          />
          {errors.ModuleName && (
            <p className="text-red-500 text-sm">{errors.ModuleName}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Module Description
          </label>
          <textarea
            placeholder="Description"
            type="text"
            name="ModuleDescription"
            value={newModule.ModuleDescription}
            onChange={handleInputChange}
            className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
          />
          {errors.ModuleDescription && (
            <p className="text-red-500 text-sm">{errors.ModuleDescription}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Learning Objectives
          </label>
          <div className="flex">
            <textarea
              type="text"
              value={newLearningObjective}
              onChange={(e) => setNewLearningObjective(e.target.value)}
              className="mt-1 p-2 block w-full border rounded-l-lg focus:ring-indigo-500 focus:border-indigo-500 flex-grow-1"
            />
            <button
              onClick={handleAddLearningObjective}
              className="px-2  bg-blue-500 text-white rounded-r-xl hover:bg-blue-700 w-8 mt-1  "
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>

          </div>
          {errors.LearningObjectives && (
            <p className="text-red-500 text-sm">{errors.LearningObjectives}</p>
          )}
        </div>

        <ul className="bg-gray-100 p-2 rounded-lg mt-2 mb-2">
          {learningObjectives.map((objective, index) => (
            <div key={index} className="mb-1">
              <div className="flex justify-between">
                <span>{objective}</span>
                <button
                  onClick={() => handleRemoveLearningObjective(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
              {index < learningObjectives.length - 1 && (
                <hr className="my-1 h-2" />
              )}
            </div>
          ))}
        </ul>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="multiSelect">
            Select module level:
          </label>

          <Select
            options={levelsOptions}
            value={selectedLevel}
            onChange={handleSelectLevelChange}
            styles={customStyles}
            className="select"
            placeholder="Select level option..."
          />

          {errors.Level && (
            <p className="text-red-500 text-sm">{errors.Level}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="multiSelect">
            Select technology:
          </label>

          <Select
            options={technologyOptions}
            value={selectedTechnology}
            onChange={handleSelectTechnologyChange}
            styles={customStyles}
            className="select"
            placeholder="Select technology option..."
          />

          {errors.Technology && (
            <p className="text-red-500 text-sm">{errors.Technology}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="multiSelect">
            Select related courses:
          </label>
          <Select
          isMulti
            options={coursesLookups}
            value={selectedCoursesOptions} 
            onChange={handleSelectCoursesChange}
            styles={customStyles}
            className="select"
            placeholder="Select multiple options..."
          />
          {errors.RelatedCourses && (
            <p className="text-red-500 text-sm">{errors.RelatedCourses}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="multiSelect">
            Select prerequisites modules:
          </label>
          <Select
            isMulti
            options={modulesLookups}
            value={selectedModulesOptions}  
            onChange={handleSelectModulesChange}
            styles={customStyles}
            className="select"
            placeholder="Select multiple options..."
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="tags">
            Module keywords:
          </label>
          <ReactTags
            className="mt-1 p-2 block w-full border rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
            tags={tags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            placeholder="Add tags..."
            classNames={tagClassNames}
          />
          {errors.ModuleKeywords && (
            <p className="text-red-500 text-sm">{errors.ModuleKeywords}</p>
          )}
        </div>
        <div className="flex justify-end mt-16">
          <button
            onClick={handleAddModule}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
          >
            {isEditMode ? "Update" : "Save"}
          </button>
        </div>

      </div>
    </Modal>
  );
};

export default AddModuleForm;
