import React, { useEffect, useState } from "react";
 
import api from "./api";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

const ViewModule = () => {
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const [moduleData, setModuleData] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await api.get(`/Module/getModule/${moduleId}`);
 console.log(response.data);
          setModuleData(response.data);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error(error);
        if(error?.response?.data?.statusCode === 401){
          navigate('/');
        }
  
       
      }
    };
  
    fetchData();
  }, [ navigate]);

  
 
   
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Module Details</h2>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Module Information</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Module Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.ModuleName}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Short Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.ShortName}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Module Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.ModuleDescription}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Level</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.Level}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Technology</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.Technology}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Learning Objectives</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.LearningObjectives && moduleData.LearningObjectives?.join(", ")}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Related Courses</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.RelatedCourses && moduleData.RelatedCourses?.map(course => course.label).join(", ")}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Prerequisite Modules</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{moduleData.PrerequisitesModules && moduleData.PrerequisitesModules?.join(", ")}</dd>
            </div>
            {/* Add more fields as needed */}
          </dl>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Activities</h3>
        <div className="mt-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activity Name</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hint</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
                {/* Add more headers as needed */}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {moduleData.Activites?.map(activity => (
                <tr key={activity._id}>
                  <td className="px-6 py-4 whitespace-nowrap">{activity.Name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{activity.Type}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{activity.Description}</td>
                  <td className="px-6 py-4 whitespace-nowrap">  <div dangerouslySetInnerHTML={{ __html: activity.Content }} />  </td>
                  <td className="px-6 py-4 whitespace-nowrap"><div dangerouslySetInnerHTML={{ __html: activity.Hint }} /> </td>
                  <td className="px-6 py-4 whitespace-nowrap">{activity.Score}</td>
                  {/* Add more columns as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewModule;
