import React, { useState } from "react";
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { JwtPayload, jwtDecode } from "jwt-decode";
import 'react-toastify/dist/ReactToastify.css';
import api from "./api";

const LoginForm = ({ type }) => {
    
    const handleLogout = () => {
        localStorage.removeItem('token');
      }
      if(type ==="logout"){
       handleLogout();
      }
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const toastOptions = {
        autoClose: 3000,
        pauseOnHover: true,
    };
    const navigate = useNavigate();
    const initialValues = {
        email: '',
        password: '',
    };
    const validationSchema = yup.object({
        email: yup.string().email('Invalid email').required('Required'),
        password: yup.string().required('Required'),
    });

  
    const handleLogin = async () => {
        try {
          const loginData = {
            email: email,
            password: password,
          };
          const response = await api.post('/auth/adminLogin', loginData);
          const access_token = response.data.token;
          const decodedData = jwtDecode(access_token);
         
          localStorage.setItem('username', decodedData.name);
          localStorage.setItem('token', access_token);
          navigate('/course');
          toast.success('Logged in successfully', toastOptions);
        } catch (error) {
            toast.error('invalid email or password', {
                autoClose: 5000,
                hideProgressBar: false,
              });
       
          console.error('Login failed', error );
        }
      };
      

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
             
        >
            {(formik) => (
                <Form>
                    <div className="flex justify-center items-center h-screen">
                        <div className="w-1/3 border border-gray-300 p-8 rounded-lg shadow-lg">
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                                    Email
                                </label>
                                <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="block w-full border rounded py-2 px-3"
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                                    Password
                                </label>
                                <Field
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="block w-full border rounded py-2 px-3"
                                    value={password} onChange={(e) => setPassword(e.target.value)} 
                                />
                            </div>

                            <button
                             onClick={handleLogin}
                               
                                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                          
                            >
                                Log In
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default LoginForm;
