import React, { useState } from "react";
import { toast } from 'react-toastify';

const UploadAttachment = ({ onFileUpload ,attachment,setAttachment }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("filse selected", file);

    const allowedTypes = ['application/pdf'];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    // Check if the file type is allowed
    if (!allowedTypes.includes(file.type)) {
      console.error('Invalid file type. Only PDF files are allowed.');
      toast.error('Invalid file type. Only PDF files are allowed.', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    // Check if the file size is within the allowed limit
    if (file.size > maxSizeInBytes) {
      console.error('File size exceeds the allowed limit.');
      toast.error('File size exceeds the allowed limit(10M).', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }

    setSelectedFile(file);
    onFileUpload(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      // You can perform additional checks here, e.g., file type, size, etc.
console.log("selectedFile", selectedFile);

      // Pass the selected file to the parent component via a callback function.
      onFileUpload(selectedFile);
    }
  };

  const handlePreview = () => {
    if (selectedFile) {
      // Create a URL for the selected file
      const fileURL = URL.createObjectURL(selectedFile);
      
      // Open the file in a new tab
      window.open(fileURL, '_blank');
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="fileInput"
      />
      <label htmlFor="fileInput" className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
        Select PDF File
      </label>
       {selectedFile && (
        <div className="mt-2">
          <p>Selected File: {selectedFile.name}</p>
          <button
            onClick={handlePreview}
            className="bg-green-500 text-white py-2 px-4 rounded cursor-pointer"
          >
            Preview PDF 
          </button>
          
        </div>
      )} 
    </div>
  );
};

export default UploadAttachment;
