import React, { useEffect, useState } from "react";
  
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 
  faSearch,
 
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import api from "./api";


const StudentTable = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
//get coureses data
  const [student, setStudents] = useState([]);
 
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await api.get("/Student/getAllStudents");
          setStudents(response.data);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error(error);
        if(error?.response?.data?.statusCode === 401){
          navigate('/');
        }
       
      }
    };
  
    fetchData();
  }, [  setStudents, navigate]);

 

 

 



  const filteredStudents = student.filter((student) =>
  student.StudentName&&student.StudentName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <div className="flex flex-col mt-48 ml-6 mr-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="flex justify-between mb-6">
              <h2 className="text-2xl font-semibold">Students Information</h2>
               
            </div>

            <div className="mb-4 relative">
              <input
                type="text"
                placeholder="Search Students"
                className="pl-10 pr-4 py-2 border rounded w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-2 top-2 text-gray-400 ml-2 mt-1"
              />
            </div>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Student name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      DateOfBirth
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                     Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gender
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Student Current Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Current Rules
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Company/University
                    </th>
            


                    
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {paginatedStudents.map((student) => (
                    <tr key={student._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                   
                          <div className="text-sm font-medium text-gray-900">
                            {student.StudentName}
                          </div>
                      
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                 
                          <div className="text-sm text-gray-900">
                            {student.DateOfBirth}
                          </div>
                     
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                            {student.Email}
                          </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                            {student.Gender}
                          </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                            {student.StudentCurrentStatus}
                          </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                            {student.CurrentRules}
                          </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                            {student.CompanyUniversity}
                          </div>
                      </td>
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="block">
            <ul className="flex pl-0 list-none rounded-lg">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li key={index}>
                  <button
                    className={`${
                      index + 1 === currentPage
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500"
                    } hover:bg-blue-300 px-4 py-2 border border-blue-500 hover:border-blue-300`}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
 
    
 
    </>
  );
};

export default StudentTable;
