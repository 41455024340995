import React from "react";
import CourseTable from "./CourseTable";
import Navbar from "./Navbar";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ModuleTable from "./ModuleTable";
import StudentTable from "./StudentTable";
import ActivityAndExerciseTable from "./ActivityAndExerciseTable";
import LoginForm from "./LoginForm";
import ViewModule from "./ViewModule";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequiredSkillsStep from "./RequiredSkillsStep";
const App = () => {
  return (
    <div>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<LoginForm type="login" />} />
          <Route path="/logout" element={<LoginForm type="logout" />} />
          <Route path="/course" element={<CourseTable />} />
          <Route path="/module" element={<ModuleTable />} />
          <Route path="/studnet" element={<StudentTable />} />
          <Route
            path="/moduleActivity/:moduleId/:moduleName"
            element={<ActivityAndExerciseTable type="activity" />}
          />
          <Route path="/RequiredSkills" element={<RequiredSkillsStep />} />
          <Route path="/viewModule/:moduleId" element={<ViewModule />} />

          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </div>
  );
};

export default App;
