import React, { useState, useRef } from 'react';
import { mainSteps } from './constants';

const RequiredSkillsStep = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [currentSubStep, setCurrentSubStep] = useState(0);
    const [completedStep, setCompletedStep] = useState({});
    const [selectedOptions, setSelectedOptions] = useState(Array(mainSteps.length).fill([]));
    const containerRef = useRef(null);

    const handleNext = () => {
        // Add logic to disable the current step's options
        setCompletedStep(prevCompletedStep => ({
            ...prevCompletedStep,
            [currentStep]: true
        }));

        if (currentSubStep < mainSteps[currentStep].subSteps.length - 1) {
            setCurrentSubStep(currentSubStep + 1);
            scrollToNextSubstep();
        } else if (currentStep < mainSteps.length - 1) {
            setCurrentStep(currentStep + 1);
            setCurrentSubStep(0); // Reset subStep index
            scrollToNextStep();
        } else {
            // Handle submission or navigation to next step
        }
    };



    const handleBack = () => {
        if (currentSubStep > 0) {
            setCurrentSubStep(currentSubStep - 1);
            scrollToPreviousSubstep();
        } else if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            setCurrentSubStep(mainSteps[currentStep - 1].subSteps.length - 1); // Set subStep to last index of previous step
            scrollToPreviousStep();
        }
    };

    const isStepCompleted = (stepIndex) => {
        return completedStep[stepIndex] && stepIndex <= currentStep;
    };

    const handleOptionSelect = (subStepIndex, optionIndex) => {
        const newSelectedOptions = [...selectedOptions];
        const currentStepOptions = newSelectedOptions[currentStep] || [];
        let currentSubStepOptions = currentStepOptions[subStepIndex] || [];

        // Check if the option is already selected
        const isSelected = currentSubStepOptions.includes(optionIndex);

        if (isSelected) {
            // If the option is already selected, deselect it
            currentSubStepOptions = currentSubStepOptions.filter((index) => index !== optionIndex);
        } else {
            // Otherwise, add the option to the selected options
            currentSubStepOptions = [...currentSubStepOptions, optionIndex];
        }

        currentStepOptions[subStepIndex] = currentSubStepOptions;
        newSelectedOptions[currentStep] = currentStepOptions;
        setSelectedOptions(newSelectedOptions);
        console.log("Selected options:", newSelectedOptions);
    };



    const setActiveStep = (stepIndex) => {
        setCurrentStep(stepIndex);
        setCurrentSubStep(0); // Reset subStep index when changing the active step
    };

    const handleSaveAndSubmit = async () => {
        // Handle save and submit logic
        console.log("submit")
    };

    const scrollToNextSubstep = () => {
        containerRef.current.scrollTo({
            top: containerRef.current.scrollTop + containerRef.current.clientHeight, // Scroll down by the height of the container
            behavior: 'smooth',
        });
    };

    const scrollToPreviousSubstep = () => {
        containerRef.current.scrollTo({
            top: containerRef.current.scrollTop - containerRef.current.clientHeight, // Scroll up by the height of the container
            behavior: 'smooth',
        });
    };

    const scrollToNextStep = () => {
        containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight, // Scroll to the bottom of the container
            behavior: 'smooth',
        });
    };

    const scrollToPreviousStep = () => {
        containerRef.current.scrollTo({
            top: 0, // Scroll to the top of the container
            behavior: 'smooth',
        });
    };



    return (
        <div className="flex justify-between h-screen w-scree bg-light-grey " style={{ backgroundColor: 'var(--light-grey, #F3F3F3)' }}>
            {/* back button */}
            <button className="bg-white flex flex-col text-gray-700 rounded-full p-2 left-0 top-30 border border-black w-12 h-12 items-center justify-center ml-5 mt-48" onClick={handleBack}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </button>

            <div className="flex flex-col space-y-6 mt-16">
                <div className='mb-4'>
                    <div className="w-973 h-54 text-greyscale-greyscale-100 font-fira-sans font-semibold text-4xl ">
                        Let’s optimize your Learning Journey
                    </div>

                    <div className="w-973 h-4 text-greyscale-greyscale-70 font-inter text-lg font-light mt-2">
                        We are collecting your inputs to feed AI to personalize your experience
                    </div>
                </div>
                {/* {question section} */}
                <div className="h-auto w-[1000px] pt-6 pl-4 bg-white pb-20 overflow-y-scroll relative [&::-webkit-scrollbar]:hidden" ref={containerRef}>
                    {/* Render two substeps */}
                    {mainSteps[currentStep].subSteps.map((subStep, subStepIndex) => (
                        <div key={subStepIndex} className="question">
                            <div className="text-lg font-semibold leading-tight mb-2 pl-6">{subStep.title}</div>
                            <div className="text-base font-light leading-5 pl-6 mb-4">{subStep.subtitle}</div>

                            <div className="flex gap-x-2 pl-6 pt-8 items-start mb-4">
                                {subStep.options.map((option, optionIndex) => (
                                    <div key={optionIndex} className="relative">
                                        <input
                                            className="peer sr-only"
                                            type="checkbox"
                                            name={`checkbox${subStepIndex}_${optionIndex}`}
                                            id={`checkbox${subStepIndex}_${optionIndex}`}
                                            checked={selectedOptions[currentStep]?.[subStepIndex]?.includes(optionIndex)}
                                            onChange={() => handleOptionSelect(subStepIndex, optionIndex)}
                                        />
                                        <label
                                            className={`peer-checked:bg-black peer-checked:text-white peer-checked:border-black relative flex items-center justify-center rounded-xl bg-gray-50 px-4 py-6 font-medium text-gray-700 mb-2 w-[180px] h-[166px] ${selectedOptions[currentStep]?.[subStepIndex]?.includes(optionIndex) ? 'bg-black text-white' : ''}`}
                                            htmlFor={`checkbox${subStepIndex}_${optionIndex}`}
                                        >
                                            {selectedOptions[currentStep]?.[subStepIndex]?.includes(optionIndex) && (
                                                <div className="peer-checked:bg-gray-100 peer-checked:ring-2 absolute right-2 top-2 h-6 w-6 rounded-full bg-white ring-offset-2 flex items-center justify-center transition-opacity">
                                                    <span className="text-black">&#10003;</span>
                                                </div>
                                            )}
                                            <span className="pointer-events-none z-10 break-words">{option}</span>
                                        </label>
                                    </div>
                                ))}
                                {/* Render "Save & go next" button for all substeps except the last one */}
                                {(currentStep !== mainSteps.length - 1 || subStepIndex !== mainSteps[currentStep].subSteps.length - 1) && (
                                    <div>
                                        <div className="flex items-center relative">
                                            <button onClick={handleBack} className={`ml-14 mt-14 cursor-pointer text-black`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7 7 7m0 0l0 0m-7-7v18" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div>
                                            <button className={`bg-black text-white rounded-l rounded-r  px-3 py-2 mt-6 ml-2 mr-2`} onClick={handleNext}>
                                                Save & go next
                                            </button>
                                        </div>

                                    </div>
                                )}

                                {/* Render Save & Submit button only when at the last substep of the last step */}
                                {currentStep === mainSteps.length - 1 && subStepIndex === mainSteps[currentStep].subSteps.length - 1 && (
                                    <button className="bg-black text-white rounded-l rounded-r px-3 py-2 mt-14 ml-2 mr-2" onClick={handleSaveAndSubmit}>
                                        Save & Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}


                </div>


            </div>

            <div className="flex flex-col space-y-8 mt-16 pr-4">
                <div className="w-221 justify-center text-black-text font-fira-sans font-bold text-24 leading-32 ">
                    Quick & Easy Setup
                </div>
                <ol className="relative text-gray-500 border-s border-t-0 border-gray-400 dark:border-gray-700 dark:text-gray-400">
                    {mainSteps.map((step, index) => (
                        <li key={index} className={`mb-8 ms-6 `} onClick={() => setActiveStep(index)}>
                            <span className={`absolute flex justify-center w-4 h-4 rounded-full -start-2.5 bg-black ring-white`}></span>
                            <h3 className="font-medium leading-tight">
                                {step.title}
                                {isStepCompleted(index) && (
                                    <span className="bg-green-600 text-white rounded-full px-2 ml-2">Done</span>
                                )}
                                {index === currentStep && (
                                    <span className="bg-black text-white rounded-full px-2 ml-2">
                                        {currentSubStep + 1} of {step.subSteps.length}
                                    </span>
                                )}
                            </h3>
                        </li>
                    ))}
                </ol>
                <div>
                    {/* Render only the image of the current step */}
                    <img src={mainSteps[currentStep].img} alt="" className='mt-8 mr-2' />
                </div>
            </div>
        </div>
    );
}

export default RequiredSkillsStep;
