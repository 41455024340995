// api.js

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//export const baseURL ='http://localhost:30000';
export const baseURL ='https://iyabbackend.thesmartlogic.com';
const api = axios.create({
 baseURL: baseURL, // Replace with your API base URL
 
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(
  (config) => {
     
    if(config?.url === "/auth/adminLogin"){
      return config;
    }
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else{
      const navigate = useNavigate();
      navigate('/');
    }
    
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default api;
