import React, { useEffect, useState } from "react";
import AddCourseForm from "./AddCourseForm";
import DeleteCourseModal from "./DeleteCourseModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import api from "./api";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
const CourseTable = () => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [editedCourse, setEditedCourse] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [newRecordAdded, setNewRecordAdded] = useState(false); // State to track if a new record is added
  const [selectedUserOption, setSelectedUserOption] = useState("");

  const itemsPerPage = 15;
  //get courses data
  const [course, setCourses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          let response;
          if (selectedUserOption) {
            response = await api.get('/Course/getCourses', {
              params: {
                user: selectedUserOption.value
              }
            });
          } else {
            // No parameters, fetch all modules
            response = await api.get('/Course/getCourses');
          }
          setCourses(response.data);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.statusCode === 401) {
          navigate("/");
        }
      }
    };

    fetchData();
  }, [newRecordAdded, setCourses, navigate, selectedUserOption]);

  const handleAddEditCourse = (courseData, isEditMode) => {
    if (isEditMode) {
      console.log("handleAddEditCourse", courseData);
      setCourses((prevCourses) =>
        prevCourses.map((c) =>
          c?._id === editedCourse?._id ? { ...c, ...courseData } : c
        )
      );
    } else {
      setCourses((prevCourses) => [...prevCourses, courseData]);
      setNewRecordAdded(true); // Set to true when a course is added
    }

    setIsAddEditModalOpen(false);
    setEditedCourse(null); // Clear editedCourse state
  };

  const openDeleteCourseModal = (id) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (course) => {
    console.log("courseData", course);
    setEditedCourse(course);
    setIsAddEditModalOpen(true);
  };

  const filteredCourses = course.filter(
    (c) =>
      c?.CourseName &&
      c?.CourseName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);

  const paginatedCourses = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const UsersOptions = [
    { value: 'Hamed Abdelhaq', label: 'Hamed Abdelhaq' },
    { value: 'Osama Daher', label: 'Osama Daher' },
    { value: 'Mohammad Jubran', label: 'Mohammad Jubran' },
    { value: 'George Yerousis', label: 'George Yerousis' },
    // Add more options as needed
  ];
  const handleCancelSearch = () => {
 
    setSelectedUserOption("");
  }
  const handleSearchByUser = (selected) => {
    console.log(selected);
 
    setSelectedUserOption(selected);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 'auto', // Set the width of the dropdown
      margin: '10px 0', // Adjust margin as needed
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e2e8f0', // Change background color of selected items
    })
  };
  return (
    <>
      <div className="flex flex-col mt-48 ml-6 mr-6 overflow-hidden">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="mb-6 grid grid-cols-4 gap-4">
              <div>
                <div className="flex justify-between mb-6">
                  <h2 className="text-2xl font-semibold"> Courses</h2>
                </div>
                <button
                  className="bg-blue-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => setIsAddEditModalOpen(true)}
                >
                  Add Course
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <input
                type="text"
                placeholder="Search Courses"
                className="pl-10 pr-4 py-2 border rounded w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-2 top-2 text-gray-400 ml-2 mt-1"
              />
            </div>

            <div className="mb-6 grid grid-cols-4 gap-4">
              <div>
                <label
                  className="block text-sm font-medium text-gray-700 mb-1"
                  htmlFor="multiSelect"
                >
                  Search by User:
                </label>

                <Select
                  options={UsersOptions}
                  value={selectedUserOption}
                  onChange={handleSearchByUser}
                  styles={customStyles}
                  className="select"
                  placeholder="Search by course"
                />
              </div>
              <div>
                <button
                  onClick={handleCancelSearch}
                  className="mt-1 px-16 py-4 bg-gray-500 text-white rounded hover-bg-blue-700 ml-10 "
                >
                  Clear search
                </button>
              </div>
            </div>
            <br/>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Course name
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Course Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Course keywords
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Domen keywords
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created By
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {paginatedCourses.map((course, index) => (
                    <tr key={course._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {index + 1}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {course.CourseName}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {course.CourseType}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {course.Description.substring(0, 50) + "..."}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-pre-line w-[15rem] overflow-hidden overflow-y-auto">
                        {course.CourseKeywords.map(
                          (option) => option.text
                        ).join(", ")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {course.DomenKeywords.map((option) => option.text).join(
                          ", "
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {course.CreatedBy}
                        </div>
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => handleEditClick(course)}
                          className="text-blue-500 hover:text-indigo-900"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        {/* <button
                          onClick={() => openDeleteCourseModal(course._id)}
                          className="text-blue-500 hover:text-indigo-900 ml-2"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>*/}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="block">
            <ul className="flex pl-0 list-none rounded-lg">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li key={index}>
                  <button
                    className={`${
                      index + 1 === currentPage
                        ? "bg-blue-500 text-white"
                        : "bg-white text-blue-500"
                    } hover:bg-blue-300 px-4 py-2 border border-blue-500 hover:border-blue-300`}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteCourseModal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        course={course}
        setCourses={setCourses}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
      />

      {/* Add/Edit Course Modal */}
      {isAddEditModalOpen && (
        <AddCourseForm
          isOpen={isAddEditModalOpen}
          onRequestClose={() => setIsAddEditModalOpen(false)}
          onAddCourse={(courseData) => handleAddEditCourse(courseData, false)}
          onUpdateCourse={(courseData) => handleAddEditCourse(courseData, true)}
          newCourse={editedCourse || {}}
          isEditMode={!!editedCourse}
        />
      )}
    </>
  );
};

export default CourseTable;
