import img1 from "./img/1.png"
import img2 from "./img/2.png"
import img3  from "./img/3.png"


export const mainSteps = [
  {
    title: "Interest and Background",
    img: img1,
    subSteps: [
      {
        title:
          "What specific areas of AI and data science are you most interested in exploring?",
        subtitle: "Choose a topic you want to learn",
        options: ["AI Literacy", "Data Science", "Big Data", "Data Management"],
      },
      {
        title:
          "How much prior experience do you have in your chosen area of interest?",
        subtitle: "Choose your level",
        options: [
          "Beginner (no prior experience)",
          "Intermediate (basic knowledge)",
          "Advanced (core concepts)",
          "Expert (basic knowledge)",
        ],
      },
      {
        title:
          "Would you prefer mini-courses in the learning journey with a specific focus (e.g., deep learning, data visualisation) or a broader overview of your chosen field?",
        subtitle: "  ",
        options: ["Specific focus", "Broad overview"],
      },
    ],
  },
  {
    title: "Specific Interests",
    img: img2,
    subSteps: [
      {
        title:
          "Do you have a particular industry or domain where you intend to apply AI and data science skills?",
        subtitle: "   ",
        options: [" No, Generic", "Healthcare", "Human Rights"],
      },
    ],
  },
  {
    title: "Future Goals",
    img: img2,
    subSteps: [
      {
        title: "What is your primary goal for taking a learning journey?",
        subtitle: "   ",

        options: [
          "Skill acquisition for career advancement",
          "Refreshing and updating existing knowledge",
          "Exploring a new field for potential career change",
          "Personal interest and intellectual curiosity",
        ],
      },
    ],
  },
  {
    title: "Targeted Skills",
    img: img3,
    subSteps: [
      {
        title:
          "Are you interested in developing skills in the following AI applications Choose a topic you want to learn ",
        subtitle: "Choose a topic you want to learn",

        options: [
          "No Preference",
          "Business Intelligence",
          "Health Informatics",
          "Predictive Analytics",
        ],
      },
      {
        title: "Preferred Learning Format :",
        subtitle: "What learning format do you prefer?",

        options: [
          "Video lectures and demonstrations",
          " Interactive exercises and coding projects",
          " Case studies and real world applications",
        ],
      },
    ],
  },
];
