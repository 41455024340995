import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
 
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UploadAttachment from "./UploadAttachment";
import { useParams } from "react-router-dom";
 import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { useNavigate } from 'react-router-dom';
import api,{baseURL} from "./api";
import { toast } from 'react-toastify';
const ActivityAndExerciseTable = ({ type }) => {
  const navigate = useNavigate();

  const { moduleId,moduleName } = useParams();
    
  const [newItem, setNewItem] = useState({
    Name: "",
    Type: "Select Type",
    Description: "",
    Score: "",
    Hint: "",
    Content:"",
    YoutubeURL:"",
    PDFURL:"",
    FileData:"",
    PartOfCode:"",
    MultipleChoiceContent: {
      Content: "",
      OptionsContent: "",
      CorrectOption: "",
    },
  });

  const contentTypes = ["Select Type","text", "code", "youtube", "pdf", "multiple-choice"];
  const itemsPerPage = 15;
  const handleFileUpload = (file) => {
    console.log("file", file);
   setNewItem((prev) => ({ ...prev, FileData: file }));
 
    // Handle file upload logic here
  };
  const [multipleChoiceContentList, setMultipleChoiceContentList] = useState([]);
  const [showContentTable, setShowContentTable] = useState(false);
//  const [correctOption, setCorrectOption] = useState("");
  const [activityData, setActivityData] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingId, setEditingId] = useState("");
  const [errors, setErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [content, setContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: [] }, { background: [] }], // Text color and background color
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "color",
    "background",
  ];
  const handleChange = (value) => {
    newItem.Content=value;
    setContent(value);
    //createMarkup();
  };
  const handleHintChange= (value) => {
    newItem.Hint=value;
    //createMarkup();
  };
 
  
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
         if (token) {
          const response = await api.get("/Module/getActivites/"+moduleId);
          setActivityData(response.data);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error(error);
        if(error?.response?.data?.statusCode === 401){
          navigate('/');
        }
      }
    };
  
 
   
  useEffect(() => {
    fetchData();
  }, [   setActivityData, navigate]); // Call fetchData whenever moduleId changes
  const validateForm = () => {
    const errors = {};

    if (newItem.Type === "Select Type") {
      errors.Type = "Type is required";
      
    }

    if (!newItem.Name.trim()) {
      errors.Name = "Name is required";

    }
    if (!newItem.Content) {
      errors.Content = "Content is required";

    }
    if (!newItem.Description.trim()) {
      errors.Description = "Description is required";
    }

    if (!newItem.Score.trim()) {
      errors.Score = "Score is required";
    }

    if(newItem.Type ==="pdf"){
      if(newItem.FileData===null || newItem.FileData==="" ){
      errors.file = "pdf file is required";
      }
    }
    if(newItem.Type ==="multiple-choice"){
      console.log("multipleChoiceContentList",multipleChoiceContentList);

      console.log("newItem.MultipleChoiceContent",newItem.MultipleChoiceContent);
      if (multipleChoiceContentList.length===0) {
        errors.ContentItem = "MultipleChoiceContent is required";
        toast.error('MultipleChoiceContent is required', {
          autoClose: 2000,
          hideProgressBar: false,
        });
      }
 
     /* if (!newItem.MultipleChoiceContent.OptionsContent) {
        newItem.MultipleChoiceContent.OptionsContent = "";
        errors.MultipleChoiceContent = {};
        errors.MultipleChoiceContent.OptionsContent = "Options Content is required";
      }
      if (!newItem.MultipleChoiceContent.CorrectOption) {
        newItem.MultipleChoiceContent.CorrectOption = "";
        errors.MultipleChoiceContent = {};
        errors.MultipleChoiceContent.CorrectOption = "Correct Option is required ";
      }*/
  
    }
    
    setErrors(errors);

    if(Object.keys(errors).length !== 0 ){
      toast.error("Please fix the errors!", {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
   
    return Object.keys(errors).length === 0;
  };

  const handleAddItem = async () => {
    if (isSubmitting) {
      return;
    }
   if (validateForm()) {
    const username = localStorage.getItem('username');
      const newItemData = {
        Name: newItem.Name,
        Type: newItem.Type,
        Description: newItem.Description,
        Hint: newItem.Hint,
        Score: newItem.Score.toString(),
        YoutubeURL:newItem.YoutubeURL,
        Content:newItem.Content,
        PDFURL:newItem.PDFURL,
        FileData:newItem.FileData,
        PartOfCode:newItem.PartOfCode,
        MultipleChoiceContent:  multipleChoiceContentList,
        CreatedBy:username
      };
      if(newItemData.Type !== 'multiple-choice'){
        newItemData.MultipleChoiceContent=[];
      }
      if(newItemData.Type !== 'pdf'){
        newItemData.FileData="";
      }
      if(newItemData.Type !== 'youtube'){
        newItemData.YoutubeURL="";
      }
      if(newItemData.Type !== 'code'){
        newItemData.PartOfCode="";
      }
    
 
      console.log("newItemData", newItemData);
       
      try {
        if (type === "activity") {
          if(newItemData.Type !== 'pdf'){
            
     
            if(editingIndex === -1 ){
              await api.post("/Module/addActivity/"+moduleId,newItemData);
              setIsSubmitting(true);
              setActivityData([...activityData, newItem]);
              toast.success('activity added successfully', {
                autoClose: 2000,
                hideProgressBar: false,
              });
            }else{
              
              await api.post("/Module/editActivity/"+moduleId+"/"+editingId,newItemData);
              setIsSubmitting(true);
              fetchData();
              toast.success('activity updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
              });
            }

          
           
          }else{
            setIsSubmitting(true);
            //handling file upload
            const formData = new FormData();
          
            formData.append('FileData', newItem.FileData);
            formData.append('Name', newItem.Name);
            formData.append('CreatedBy',username);
            formData.append('Type', newItem.Type);
            formData.append('Description', newItem.Description);
            formData.append('Hint', newItem.Hint);
            formData.append('Score', newItem.Score.toString());
            formData.append('YoutubeURL', newItem.YoutubeURL);
            formData.append('PartOfCode',"");
            formData.append('Content', newItem.Content);
            formData.append('PDFURL', newItem.PDFURL);
            if(editingIndex === -1 ){
              const response = await api.post('/Module/addActivityWithPDF/'+moduleId, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              newItem.PDFURL = "uploads/"+response.data.filename;
              setActivityData([...activityData, newItem]);
              toast.success('activity added successfully', {
                autoClose: 2000,
                hideProgressBar: false,
              });
            }else{
              const response = await api.post('/Module/editActivityWithPDF/'+moduleId+"/"+editingId, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              newItem.PDFURL = "uploads/"+response.data.filename;
              fetchData();
              toast.success('activity updated successfully', {
                autoClose: 2000,
                hideProgressBar: false,
              });
            }
              
               
              
          }
          
        
         
        }  
        console.log("activityData", activityData);

        setMultipleChoiceContentList([]);
        setShowContentTable(false);
        setContent("");

        setNewItem({
          Name: "",
          Type: "Select Type",
          Description: "",
          Score: "",
          Hint: "",
          Content:"",
          YoutubeURL:"",
          PDFURL:"",
          PartOfCode:"",
          MultipleChoiceContent: {
            Content: "",
            OptionsContent: "",
            CorrectOption: "",
          }
        });
        setIsSubmitting(false);
      } catch (error) {
        toast.error('An error occurred, please try again', {
          autoClose: 2000,
          hideProgressBar: false,
        });
        console.log("Error sending data to Nest API:", error);
          if(error?.response?.data?.statusCode === 401){
          navigate('/');
        }
      }
    
    };
  };
//handling add MultipleChoiceContentList
  const addContentItem = () => {
    const contentItem = newItem.MultipleChoiceContent;
    console.log(contentItem);
    if(newItem.Content ===''  || contentItem.OptionsContent=== ''|| contentItem.CorrectOption==='' ) {
      toast.error('Please fill all required data!', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      return;
    }
    console.log("contentItem",contentItem);
    if (contentItem.CorrectOption && newItem.Content && contentItem.OptionsContent) {
      const options = newItem.MultipleChoiceContent.OptionsContent.split(",").map((option) =>
        option.trim()
      );
      const isMultipleChoice = newItem.Type === "multiple-choice";

      if (isMultipleChoice) {
        contentItem.Content = newItem.Content;
        contentItem.OptionsContent = options;

        contentItem.CorrectOption =  newItem.MultipleChoiceContent.CorrectOption.toString();
      }

      setMultipleChoiceContentList([...multipleChoiceContentList, contentItem]);

      setNewItem({
        ...newItem,
        MultipleChoiceContent: {
          Content: "",
          OptionsContent: "",
          CorrectOption: "",
        },
      });
      //setCorrectOption("");
      setContent("");
      setShowContentTable(true);
    }
   
  };

  const isActivity = type === "activity";
  const data = isActivity ? activityData : exerciseData;

  const isCode = newItem.Type === "code";
  const isLink = newItem.Type === "youtube";
  const isPdf = newItem.Type === "pdf";
  const isMultipleChoice = newItem.Type === "multiple-choice";

  const handleEditItem = (index) => {
    setEditingIndex(index);

    const editedItem = data[index];
    console.log("editedItem",editedItem);
    setEditingId(editedItem._id);
    setContent(editedItem.Content);
    setNewItem({
      Name: editedItem.Name,
      Type: editedItem.Type,
      Description: editedItem.Description,
      Hint: editedItem.Hint,
      Score: editedItem.Score,
      Content:editedItem.Content,
      YoutubeURL:editedItem.YoutubeURL,
      PDFURL:editedItem.PDFURL,
      PartOfCode:editedItem.PartOfCode,
      MultipleChoiceContent: {
        Content: "",
        OptionsContent: "",
        CorrectOption: "",
      },
     
    });
    setMultipleChoiceContentList(editedItem.MultipleChoiceContent);
    if( editedItem.Type === "multiple-choice"){
      setShowContentTable(true);
    }else{
      setShowContentTable(false);
    }
  };

  const handleSaveItem = (index) => {
    setEditingIndex(-1);

    const updatedData = [...data];
    updatedData[index] = {
      Name: newItem.Name,
      Type: newItem.Type,
      Description: newItem.Description,
      Content: newItem.Content,
      MultipleChoiceContent:multipleChoiceContentList
    };

    if (type === "activity") {
      setActivityData(updatedData);
    } else if (type === "exercise") {
      setExerciseData(updatedData);
    }

    setNewItem({
   Name: "",
      Type: "Select Type",
      Description: "",
      Score: "",
      Hint: "",
      Content:"",
      YoutubeURL:"",
      PDFURL:"",
      PartOfCode:"",
      MultipleChoiceContent: {
        Content: "",
        OptionsContent: "",
        CorrectOption: "",
      }
    });
    setMultipleChoiceContentList([]);
  };
  const handlePreview = (fileName) => {
    console.log("fileName1",fileName);
    if (fileName) {
      // Create a URL for the selected file
      const fileURL = `${baseURL}/${fileName}`;
      
      // Open the file in a new tab
     window.open(fileURL, '_blank');
    }
  };
  const handleCancelEdit = () => {
    
    setEditingIndex(-1);
    setMultipleChoiceContentList([]);
    setShowContentTable(false);
    setContent("");
    setNewItem({
      Name: "",
      Type: "Select Type",
      Description: "",
      Score: "",
      Hint: "",
      Content:"",
      YoutubeURL:"",
      PDFURL:"",
      PartOfCode:"",
      MultipleChoiceContent: {
        Content: "",
        OptionsContent: "",
        CorrectOption: "",
      }
    });
 
  };

  const [editingContentIndex, setEditingContentIndex] = useState(-1);
  //const [editingCorrectOption, setEditingCorrectOption] = useState("");

  const handleContentEdit = (index) => {
    setEditingContentIndex(index);

    const editedContent = multipleChoiceContentList[index];
    setNewItem({
      ...newItem,
      MultipleChoiceContent: {
        
        Content:editedContent.Content,
        OptionsContent: editedContent.OptionsContent,
        CorrectOption: editedContent.CorrectOption,
      },
    });

     
  };

  const handleContentSave = (index) => {
    setEditingContentIndex(-1);

    const updatedContentList = [...multipleChoiceContentList];
    updatedContentList[index] = {
      Content: newItem.Content,
      OptionsContent: newItem.MultipleChoiceContent.OptionsContent,
      CorrectOption:newItem.MultipleChoiceContent.CorrectOption,
        //newItem.Type === "multiple-choice" ? editingCorrectOption : null,
    };

    setMultipleChoiceContentList(updatedContentList);

    if (type === "activity") {
      setActivityData(updatedContentList);
    } else if (type === "exercise") {
      setExerciseData(updatedContentList);
    }
    setNewItem({
      ...newItem,
      MultipleChoiceContent: {
        Content: "",
        OptionsContent: "",
        CorrectOption: "",
      },
    });
  };

  const handleContentDelete = (index) => {
    const updatedContentList = multipleChoiceContentList.filter((_, i) => i !== index);
    setMultipleChoiceContentList(updatedContentList);

    if (type === "activity") {
      setActivityData(updatedContentList);
    } else if (type === "exercise") {
      setExerciseData(updatedContentList);
    }
  };
  const totalPages = Math.ceil(activityData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedModules = activityData.slice(startIndex, endIndex);

  return (
    <div className="flex flex-col mt-16 ml-6 mr-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="flex justify-between mb-6">
            <h2 className="text-2xl font-semibold">
              {isActivity ? "Add Activity for module: " : "Add Exercise"} <b>{moduleName}</b>
            </h2>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              placeholder="Name"
              value={newItem.Name}
              onChange={(e) => setNewItem({ ...newItem, Name: e.target.value })}
              className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
            />
            {errors.Name && (
              <p className="text-red-500 text-sm">{errors.Name}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              placeholder="Description"
              value={newItem.Description}
              onChange={(e) =>
                setNewItem({ ...newItem, Description: e.target.value })
              }
              className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
            />
            {errors.Description && (
              <p className="text-red-500 text-sm">{errors.Description}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Marks(score)
            </label>
            <input
              type="number"
              placeholder="Marks(score)"
              value={newItem.Score || ""}
              onChange={(e) =>
                setNewItem({ ...newItem, Score: e.target.value })
              }
              className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
            />
            {errors.Score && (
              <p className="text-red-500 text-sm">{errors.Score}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Hint
            </label>
             
            <ReactQuill
      
                      value={newItem.Hint }
                       onChange={handleHintChange}
                      modules={modules}
                      formats={formats}
                      placeholder="Type something..."
                      theme="snow"
                    />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Type
            </label>
            <select
              value={newItem.Type}
              onChange={(e) => setNewItem({ ...newItem, Type: e.target.value })}
              className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
            >
              {contentTypes.map((contentType) => (
                <option key={contentType} value={contentType}>
                  {contentType}
                </option>
              ))}
            </select>
            {errors.Type && (
              <p className="text-red-500 text-sm">{errors.Type}</p>
            )}
          </div>

          <div className="mb-4">
            <div className="flex flex-wrap items-end  ">
              <div className="w-full  mb-4 pr-2">
              
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                    Activity Content  
                    </label>
                    <ReactQuill
                      value={content}
                      onChange={handleChange}
                      modules={modules}
                      formats={formats}
                      placeholder="Type something..."
                      theme="snow"
                    />

                    <div>
                      <h3>Displayed Content:</h3>
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                    {errors.Content && (
              <p className="text-red-500 text-sm">{errors.Content}</p>
            )}
                  </div>
          {isCode && (      
           <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Part of code
            </label>
            <textarea
              placeholder="part of code"
              value={newItem.PartOfCode}
              onChange={(e) =>
                setNewItem({ ...newItem, PartOfCode: e.target.value })
              }
              className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
            />
          
          </div> 
             )}

                {isLink && (
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      YouTube Link
                    </label>
                    <input
                      type="text"
                      placeholder="YouTube Link"
                      value={newItem.YoutubeURL}
                      onChange={(e) => setNewItem({ ...newItem, YoutubeURL: e.target.value })}

                   
                      className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
                    />
                  </div>
                )}

                {isPdf && (
                  
                         <div className="mb-4">
                         <label className="block text-sm font-medium text-gray-700">
                           Upload PDF
                         </label>
                         <div className="mt-8">
                           <UploadAttachment
                             onFileUpload={handleFileUpload}
                             attachment={newItem.FileData}
                             setAttachment={(attachment) =>
                               setNewItem({
                                 ...newItem,
                                 FileData: attachment  
                               })
                             }
                           />
                         </div>
                         {errors.file && (
            <p className="text-red-500 text-sm">{errors.file}</p>
          )}
                       </div>  
          
                )}
          
                {isMultipleChoice && (


                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Options
                    </label>
                    <textarea
                      placeholder="Enter options (separated by commas) Ex: option one, option two,.."
                      value={newItem.MultipleChoiceContent.OptionsContent}
                      onChange={(e) =>
                        setNewItem({
                          ...newItem,
                          MultipleChoiceContent: {
                            ...newItem.MultipleChoiceContent,
                            OptionsContent: e.target.value,
                          },
                        })
                      }
                      className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
                    />

                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Correct Option
                      </label>
                      <input
                           type="number"
                        placeholder="Enter the correct option number"
                        value={newItem.MultipleChoiceContent.CorrectOption}
                        onChange={(e) =>
                          setNewItem({
                            ...newItem,
                            MultipleChoiceContent: {
                              ...newItem.MultipleChoiceContent,
                              CorrectOption: e.target.value,
                            },
                          })
                        }
                        className="mt-1 p-2 block w-full border rounded-lg focus-ring-indigo-500 focus-border-indigo-500"
                      />
                    </div>
                  </div>
                )}
                {isMultipleChoice && (
                <button
                  onClick={addContentItem}
                  className="px-2 bg-blue-500 text-white rounded hover-bg-blue-700 ml-2"
                >
                  Add Multiple-Choice content
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                )}
                { showContentTable && (
                  <table className="min-w-full divide-y divide-gray-200 mt-6">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="text-left">Multiple Choice Title</th>
                        <th className="text-left">Options</th>
                
                          <th className="text-left">Correct Answer</th>
                    
                        <th className="text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {multipleChoiceContentList.map((content, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-gray-100" : "bg-white"
                          }
                        >
                          <td className="px-1 py-2 text-left">
                            {editingContentIndex === index ? (
                              <input
                                type="text"
                                value={newItem.Content}
                                onChange={(e) =>
                                  setNewItem({
                                    ...newItem,
                                    MultipleChoiceContent: {
                                      ...newItem.MultipleChoiceContent,
                                      Content: e.target.value,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <div dangerouslySetInnerHTML={{ __html:  content.Content }} />
                             
                            )}
                          </td>
                          <td className="px-1 py-2 text-left">
                            {editingContentIndex === index ? (
                              <input
                                type="text"
                                value={newItem.MultipleChoiceContent.OptionsContent}
                                onChange={(e) =>
                                  setNewItem({
                                    ...newItem,
                                    MultipleChoiceContent: {
                                      ...newItem.MultipleChoiceContent,
                                      OptionsContent: e.target.value,
                                    },
                                  })
                                }
                              />
                            ) : (
                              content.OptionsContent
                            )}
                          </td>
               
                            <td className="px-1 py-2 text-left">
                              {editingContentIndex === index ? (
                                <input
                                  type="text"
                                  value={newItem.MultipleChoiceContent.CorrectOption}
                                  onChange={(e) =>
                                    setNewItem({
                                      ...newItem,
                                      MultipleChoiceContent: {
                                        ...newItem.MultipleChoiceContent,
                                        CorrectOption: e.target.value,
                                      },
                                    })
                                  }
                                />
                              ) : (
                                content.CorrectOption
                              )}
                            </td>
                         
                          <td className="px-1 py-2 text-left">
                            {editingContentIndex === index ? (
                              <div>
                                <button
                                  onClick={() => handleContentSave(index)}
                                  className="text-blue-500 hover:text-indigo-900 pr-2"
                                >
                                  Save
                                </button>

                                <button
                                  onClick={() => {
                                    setEditingContentIndex(-1);
                                //    setEditingCorrectOption("");
                                  }}
                                  className="text-blue-500 hover:text-indigo-900 ml-10"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                {/* <button
                                  onClick={() => handleContentEdit(index)}
                                  className="text-blue-500 hover:text-indigo-900 pr-2"
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </button> */}
                                  <button
                                  onClick={() => handleContentDelete(index)}
                                  className="text-blue-500 hover:text-indigo-900"
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          <button
            onClick={handleAddItem}
            className="px-16 py-4 bg-blue-500 text-white rounded hover-bg-blue-700 "
            disabled={isSubmitting}
          >
            { editingIndex === -1 ? "Save" : "Update"}
            {isSubmitting ? 'Saving...' : ' '}
          </button>

          <button
            onClick={handleCancelEdit}
            className="px-16 py-4 bg-gray-500 text-white rounded hover-bg-blue-700 ml-10 "
           
          >
               
            Cancel  
          </button>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg  mt-20">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hint</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Activity  Content</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created By</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="px-1 py-2 text-left">
                    { 
                      item.Name
                     }
                  </td>
                  <td className="px-1 py-2 text-left">
                    { 
                      item.Type
                     }
                  </td>
                  <td className="px-1 py-2 text-left">
                    { 
                      item.Description.substring(0, 200) +'...'
                  
                     }
                  </td>
                  <td className="px-1 py-2 text-left">
                    { 
                      <div dangerouslySetInnerHTML={{ __html:  item.Hint }} />
                    }
                  </td>
                  <td className="px-1 py-2 text-left">
                    { 
                      item.Score
                     }
                  </td>
                  <td className="px-1 py-2 text-left">
                    { 
                 
                      <div dangerouslySetInnerHTML={{ __html:  item.Content.substring(0, 200) }} />
                    }
                  </td>
                  {item.Type==="pdf"  && (
                        <td className="px-1 py-2 text-left">
                             <button
                               
                              onClick={() => handlePreview(item.PDFURL)} 
                              className="bg-green-500 text-white py-2 px-4 rounded cursor-pointer"
                            >
                              Preview PDF 
                            </button>
                       
                        
                      </td>
                   )}
                  {item.Type==="youtube" && (    <td className="px-1 py-2 text-left">
                        { 
                          item.YoutubeURL
                        }
                      </td>)}
                      {item.Type==="text"    && (    <td className="px-1 py-2 text-left">
                        { 
                          "Note:Text content same content!"
                        }
                      </td>)}
                      {  item.Type === "code" && (    <td className="px-1 py-2 text-left">
                        { 
                          "Note:code content same content!"
                        }
                      </td>)}
                  {item.Type==="multiple-choice"  && (<td className="px-1 py-2 text-left">
                    <button
                      onClick={() => {
                        setMultipleChoiceContentList(item.MultipleChoiceContent);
                        setShowContentTable(true);
                      }}
                      className="text-blue-500 hover:text-indigo-900 "
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      View Content
                    </button>
                  </td>)}

                  <td className="px-1 py-2 text-left">
                    { 
                      item.CreatedBy
                     }
                  </td>
                  <td className="px-1 py-2 text-left">
                    {
                      <button
                        onClick={() => handleEditItem(index)}
                        className="text-blue-500 hover:text-indigo-900 pr-2"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                     }
                   {/* <button
                      onClick={() => {
                        const newData = data.filter((_, i) => i !== index);
                        if (isActivity) {
                          setActivityData(newData);
                        } else {
                          setExerciseData(newData);
                        }
                      }}
                      hidden={editingIndex === index}
                      className="text-blue-500 hover:text-indigo-900"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          
          <div className="flex justify-center mt-4">
              <nav className="block">
                <ul className="flex pl-0 list-none rounded-lg">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li key={index}>
                      <button
                        className={`${index + 1 === currentPage
                          ? "bg-blue-500 text-white"
                          : "bg-white text-blue-500"
                          } hover-bg-blue-300 px-4 py-2 border border-blue-500 hover-border-blue-300`}
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityAndExerciseTable;
